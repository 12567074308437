export default {
  usernamePla: '请输入账号',
  passwordPla: '请输入密码',
  forgetPwd: '忘记密码',
  btnLogin: '登 录',
  loginMsg: '账号或密码错误',
  loginSuccess: '登录成功',
  back: '返回登陆',
  emailPla: '请输入邮箱',
  codePla: '请输入验证码',
  getCode: '获取验证码',
  resetCode: '重新获取{number}s', // 例如重新获取10s
  newPwdPla: '请输入新密码',
  btnReset: '重置密码',
  newPasswordPla: '请输入新密码',
  pwdMsg: '请输入8-16位字母，数字，特殊符号两种及以上组合',
  codeMsg: '请输入验证码',
  codeError: '验证码错误',
  emailFirst: '请先输入邮箱',
  emailMsg: '请输入正确的邮箱',
  updatePwdTitle: '修改密码',
  oldPwd: '旧密码',
  oldPwdPla: '请输入旧密码',
  newPwd: '新密码',
  confirmPwd: '确认新密码',
  confirmPwdPla: '请再次输入新密码',
  btnCancel: '取消',
  btnYes: '确认',
  pwdMsgMatch: '两次输入的密码不匹配',
  oldMsg: '请输入原密码',
  pwdAgain: '请再次输入新密码',
  updatePwd: '修改密码',
  signOut: '退出',
  updateSuccess: '修改密码成功',
  oldPwdError: '原密码错误',
}
