export default {
  siteName: 'Site Name',
  col1: 'Site Name',
  col2: 'Belonging Organization',
  col3: 'Number Of Devices',
  col4: 'Describe',
  col5: 'Creation Time',
  btnAdd: 'Add Site',
  titleNew: 'New site',
  titleEdit: 'Edit site',
  uploadPic: 'Upload image',
  address: 'Position',
  remark: 'Describe',
  relativeDevice: 'Associated devices',
  delTip: 'Are you sure you want to delete this site?',
  siteNamePla: 'Enter',
  orgPla: 'Select',
  addressEdit: 'Edit Position',
  remarkPla: 'Enter',
  yes: 'Comfirm',
  no: 'Cancel',
  siteInfomation: 'Site information',
  associatedDevices: 'Associated devices',
  siteMap: 'Site Map',
  desc: 'Describe',
  noAddress: 'No site location information available at the moment',
  onlineTip: '{text} online devices',
  noActiveTip: '{text} Inactivated devices',
  offlineTip: '{text} offline devices',
}
