import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 用户分页列表
export const queryUserPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/user/page',
    params,
  })
}
// 添加用户
export const queryUserAdd = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/user/add',
    params,
  })
}
// 删除用户
export const queryUserDelete = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/user/delete',
    params,
  })
}
// 修改用户
export const queryUserUpdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/user/update',
    params,
  })
}
// 查询用户组织
export const queryUserOrg = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.USER,
    url: '/userorg/query',
    params,
  })
}
// 查询角色下拉列表
export const queryRoleList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.USER,
    url: '/role/treelist',
    params,
  })
}
// 查询用户详情
export const queryUserDetail = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.USER,
    url: '/user/detail',
    params,
  })
}
// 查询用户权限
export const queryUsersCurrentFull = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.USER,
    url: '/users/currentFull',
    params,
  })
}

// 查询当前租户勾选的权限
export const queryUsersOrgResource = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.USER,
    url: '/users/orgResource',
    params,
  })
}

// 角色分野
export const queryRolePage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/role/page',
    params,
  })
}

// 添加角色
export const addRoleInfo = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/role/add',
    params,
  })
}

// 更新角色
export const updateRoleInfo = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/role/update',
    params,
  })
}

// 删除角色
export const deleteRoleInfo = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/role/delete',
    params,
  })
}

export const updateRoleStatus = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/role/startAndStop',
    params,
  })
}

export const queryRoleDetail = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.USER,
    url: '/role/detail',
    params,
  })
}

export const queryRoleTreeList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.USER,
    url: '/role/treelist/curorg',
    params,
  })
}
