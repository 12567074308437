export default {
  home: '首页',
  deviceManage: '设备管理',
  deviceInfo: '设备列表',
  deviceLocation: '设备分布',
  deviceTrail: '轨迹回放',
  deviceLocus: '电子围栏',
  deviceMsg: '设备消息',
  deviceLog: '设备日志',
  dataManage: '数据中心',
  dataHistory: '历史数据',
  cloudManage: '云开发',
  cloudAccount: 'API账户',
  cloudPush: '消息推送',
  cloudApiList: 'API列表',
  warningManage: '通知告警',
  warningTactics: '告警策略',
  warningContact: '告警联系人',
  warningPush: '告警推送',
  warningMsg: '告警消息',
  configManage: '系统管理',
  configUser: '用户管理',
  configOrgManage: '组织管理',
  configLog: '诊断日志',
  deviceData: '设备数据',
  deviceEdit: '编辑设备',
  deviceNew: '新建设备',
  auth: '暂无权限',
  bigScreen: '监控大屏',
  bigScreenFarm: '牧业大屏',
  bigScreenPark: '森林公园大屏',
  kanban: '看板',
  reportConfiguration: '报表配置',
  reportQuery: '报表查询',
  fileQuery: '文件查询',
  videoMonitoring: '视频监控',
  rolesConfiguration: '角色管理',
  siteManagement: '站点管理',
  siteList: '站点列表',
  siteDistribution: '站点分布',
  banner1:
    '可查询设备的历史数据，支持按时间段查询数据的历史趋势图，支持数据导出。',
  banner2: '可管理设备，可配置设备的参数，可关联子设备。',
  banner3:
    '可设置触发告警的规则，支持规则组合，可设置告警触发的时间，可开启和关闭告警。',
  banner4:
    '可查看和处理产生的告警消息，可根据告警的名称、类型、级别、状态、时间进行过滤',
  banner5: '可管理告警联系人。',
  banner6: '可设置告警联系人和推送方式，支持邮件或短信两种推送方式。',
  banner7: '可管理用户，支持关联角色。',
  banner8:
    '可查询诊断日志，可根据租户、设备名称、日志ID、日志类型、卫星ID、采集时间和上报时间查询，支持数据导出。',
  banner9: '可管理组织架构，支持多级组织。',
}
