export default {
  btnNew: 'New fence',
  btnClose: 'Exit',
  btnSave: 'Save Edit',
  btnEdit: 'Edit',
  btnRelativeDevice: 'Associated Device',
  btnDelete: 'Delete',
  msgDeleteTip:
    'Deleting the fence may cause the alarm associated with the fence to be unavailable. Are you sure to delete it? ',
  deleteTipTitle: 'Delete Tip',
  btnCancel: 'Cancel',
  drawStartTip: 'Click to start drawing',
  drawContTip: 'Click to continue',
  drawEndTip: 'Click the first point to end drawing',
  delSuccess: 'Delete successful',
  delError: 'Delete failed',
  labelAoiName: 'name',
  labelRelativeDevice: 'Device',
  btnYes: 'OK',
  newTitle: 'New fence ',
  editTitle: 'Edit fence ',
  enterPla: 'Please enter',
  editSuccess: 'Modify fence successfully',
  newSuccess: 'New fence succeeded',
  polygon: 'Polygon',
  round: 'Circular',
  circleTip1: 'Click and drag to draw a circle',
  circleTip2: 'Release the mouse to complete the drawing',
  radius: 'Radius',
  typeIn: 'Entry Direction',
  typeOut: 'Exit Direction',
  typeInOut: 'Entry/Exit Direction',
  alarmTip: 'Auto matically Add fence Alarm',
  geoDiretion: 'Direction',
}
