// 字母，数字，特殊符号(~!@#$%^&*()_.)，两种及以上组合，8-16位字符串
export const passwordReg = /^.{6,30}$/

// 判断是否为经度
export const lngReg = /^[-+]?((180(\.0+)?)|((?!00)\d{1,2}|1[0-7]\d)(\.\d+)?)$/

// 判断是否为伟度
export const latReg = /^-?((([1-8]?\d{1})\.\d+)|([1-8]?\d{1}|90|0))$/

// 手机号码
export const phoneReg = /^[1-9]\d{10}$/

// 用户管理-帐号
export const accountReg = /^[a-zA-Z0-9]{4,20}$/
