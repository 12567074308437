import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 查询轨迹
export const queryDevicePlayback = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/playback/bycondit',
    params,
  })
}
// 查询轨迹分页
export const queryDevicePlaybackPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/playback/page',
    params,
  })
}

// 设备轨迹添加
export const deviceLocationAdd = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/location/add',
    params,
  })
}

// 设备轨迹添加
export const exportDeviceTrail = (params: any) => {
  return post({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/playback/export',
    params,
    userConfig: {
      responseType: 'arraybuffer',
    },
  })
}
// 保存设备轨迹回放图标
export const queryDevicePlaybackIcon = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/save/track/playback/icon',
    params,
  })
}
