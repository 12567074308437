export const obj = [
  '119.92023602910163,28.39346757424379',
  '119.9166472334396,28.388908831868108',
  '119.89109114594622,28.36768875196116',
  '119.8764516491425,28.35101555198132',
  '119.83127788491475,28.33410836881341',
  '119.82574180550789,28.330656725367472',
  '119.80174676366092,28.310246769938654',
  '119.76010815091249,28.29008325313986',
  '119.68886867947514,28.2919632646402',
  '119.67308119721312,28.289369973757307',
  '119.63514939732356,28.283266752136946',
  '119.62252155728095,28.29539727027906',
  '119.62652341313137,28.309014113643066',
  '119.61546198315355,28.317902142979055',
  '119.60778550095272,28.324985587606673',
  '119.59713176674529,28.335222692140903',
  '119.58972886986385,28.346648553585258',
  '119.58372072167028,28.349953325657754',
  '119.58028749413103,28.352445999975746',
  '119.56776157803108,28.369557950843102',
  '119.56479338098976,28.38142098698101',
  '119.56043747354937,28.40047242049131',
  '119.56319478441686,28.410353088477112',
  '119.56664946962819,28.417336003769417',
  '119.55686477114155,28.429224772977694',
  '119.54705861498277,28.44037635687803',
  '119.5281114905012,28.455545152295585',
  '119.37780049730672,28.62947185558699',
  '119.39497736383868,28.65999762787386',
  '119.63250306536146,28.272053351842217',
  '119.62707427431515,28.258757811730014',
  '119.62298658777647,28.24326788087191',
  '119.61061623979968,28.22912745651921',
  '119.60219300070062,28.222668986359512',
  '119.59404981413128,28.217441153819625',
  '119.5821515349409,28.20679562586301',
  '119.56563985624481,28.19973268752965',
  '119.55229318418687,28.184101795004246',
  '119.5426104096429,28.169896788142314',
  '119.53580832758168,28.153518968681258',
  '119.5351645974182,28.127894928922633',
  '119.5353416232131,28.120864630676856',
  '119.50645131473289,28.08814305024656',
  '119.46876091365462,28.070630928234408',
  '119.36111313704815,28.063402795843025',
  '119.32582599524827,28.067795589135525',
  '119.30293065909655,28.077924837375665',
  '119.2554609626499,28.06720323567207',
  '119.20927093775708,28.041672711230735',
  '119.22486755283587,28.049480804317678',
]
