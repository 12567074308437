export default {
  strategyName: 'Alarm Name',
  strategyType: 'Categories',
  strategyStatus: 'Status',
  btnNew: 'New Alarm Policy',
  colName: 'Alarm Name',
  colTypeName: 'Categories',
  colPushMsgTotal: 'Alarm Message',
  colUpdateTime: 'Time',
  colStatus: 'Status',
  statusOpen: 'Opened',
  statusClose: 'Closed',
  colOperation: 'Operate',
  btnEdit: 'Edit',
  btnDel: 'Delete',
  delTip: 'Are you sure you want to delete this alarm policy? ',
  btnView: 'View',
  closeTip: 'Are you sure you want to close this alarm? ',
  openTip: 'Are you sure you want to enable this alarm? ',
  open: 'Open',
  close: 'Close',
  delSuccess: 'Delete successful',
  labelAoi: 'Warning Fence',
  labelActionType: 'Effective time',
  labelTypeName: 'Alarm Category',
  labelDeviceName: 'Device Name',
  labelAttr: 'Alarm indicator',
  week1: 'Monday',
  week2: 'Tuesday',
  week3: 'Wednesday',
  week4: 'Thursday',
  week5: 'Friday',
  week6: 'Saturday',
  week7: 'Sunday',
  actionTypeAlways: 'Always',
  labelNameForm: 'Alarm Name',
  labelNamePla: 'Please enter the alarm name',
  labelAoiForm: 'Fence selection',
  labelVarForm: 'variable',
  devicePla: 'Device',
  subDevicePla: 'Sub-device',
  varPla: 'Variable',
  labelOperatior: 'Operator',
  labelThreshold: 'Threshold',
  thresholdPla: 'Threshold',
  labelEffective: 'Effective time',
  editTitle: 'Edit alarm policy',
  newTitle: 'New alarm policy',
  effectTimeMsg: 'Please choose a custom time option',
  editSuccess: 'Edit alarm succeeded',
  newSuccess: 'The alarm was added successfully',
  operatorError: 'Operation failed',
  timeAlways: 'Always',
  timeCustom: 'Custom time',
  timeRange: 'Time range',
  timeStart: 'Start time',
  timeEnd: 'End time',
  delError: 'Delete failed',
  delTitle: 'Delete confirmation',
  btnOk: 'OK',
  btnCancel: 'Cancel',
  comfirmTitle: 'Tips',
  enterMsg: 'Please enter',
  selectMsg: 'Please select',
  alarmLevel: 'Alarm Level',
  operator1: 'greater than',
  operator2: 'less than',
  operator3: 'equal to',
  operator4: 'greater than or equal',
  operator5: 'less than or equal',
  operator6: 'Greater than or equal to and less than or equal to',
  and: ' & ',
  condition: 'Condition',
  conditionAll: 'Satisfy all',
  conditionOne: 'Satisfy one of them',
  mode: 'Mode',
  select: 'Select',
  range: 'Range',
  keepTime: 'Duration',
  count: 'count',
  add: 'Add',
  main: 'Major',
  minor: 'Minor',
  urgency: 'Urgent',
  warn: 'Warning',
  clean: 'Clear',
  simple: 'Simple',
  duringTime: 'Duration time',
  duringNum: 'Duration count',
  time1: 'second',
  time2: 'minute',
  time3: 'hour',
  time4: 'day',
  sensorsMsg: 'Please add conditions',
  triggerCondition: 'Trigger Condition',
  alarmConditions: 'Alarm Condition',
  deviceName: 'Device Name',
  changeValue: 'Change value',
  changeValuePla: 'Enter change value',
}
