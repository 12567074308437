export default {
  deviceName: '设备名称',
  deviceNamePla: '请选择设备',
  autoFetchText: '自动刷新',
  autoFetchTip: '每十五秒自动刷新一次',
  productType1: '卫星多模边缘计算网关',
  productType2: '卫星单模网关',
  msgNoLocation: '暂无设备定位信息',
  device: '设备名称',
  address: '设备地址',
  lat: '纬度',
  lng: '经度',
  time: '时间',
}
