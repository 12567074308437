export default {
  deviceName: '设备名称',
  deviceNamePla: '请选择设备',
  subDevice: '子设备',
  subDevicePla: '请选择子设备',
  varList: '子设备变量',
  varListPla: '请选择子设备变量',
  range: '时间段',
  startTime: '开始时间',
  endTime: '结束时间',
  deviceInfo: '设备信息',
  deviceMap: '设备地图',
  historyChart: '历史趋势图',
  selectPla: '请选择',
  historyData: '历史数据',
  msgRangeTime: '时间跨度要小于一年',
  noData: '暂无数据',
  max: '最高',
  min: '最低',
  btnExport: '导出excel',
  downloading: '正在下载',
  varName: '属性名称',
  varMsg: '请选择属性',
  rangeMsg: '请选择时间段',
  chartTitle: '历史趋势图',
  realData: '实时数据',
  aggregatedData: '聚合数据',
  maximum: '最大条数',
  aggregatedConditions: '聚合条件',
  timeStep: '时间间隔',
  minute: '分钟',
  h: '小时',
  d: '天',
  month: '月',
  y: '年',
  condition1: '最大值和最小值',
  condition2: '最大值',
  condition3: '最小值',
  condition4: '平均值',
  condition5: '求和值',
  condition6: '计数值',
  condition7: '差值',
  enter: '请输入',
  select: '请选择',
  search: '查询',
  reportName: '报表名称',
  reportConfNew: '新增报表',
  executionDevice: '执行设备',
  viewDevice: '查看设备',
  viewTitle: '执行设备属性',
  reportStatus: '启用状态',
  reportStatus1: '启用',
  reportStatus0: '关闭',
  operation: '操作',
  device: '设备',
  var: '属性',
  yes: '确定',
  delete: '删除',
  dateType: '数据类型',
  executionType: '执行方式',
  confDelTip: '您确定要删除这个配置吗',
  rffTitle: '新建报表',
  rffTitleEdit: '编辑报表',
  rfName: '报表名称',
  rfNamePla: '请输入报表名称',
  rfDateType: '数据类型',
  rfDateTypePla: '请选择数据类型',
  rfStatisticsType: '聚合条件',
  rfStatisticsTypePla: '请选择聚合条件',
  rflimit: '最大条数',
  rflimitPla: '请输入最大条数',
  rflatestTime: '最近时间',
  rflatestTimePla: '请选择最近时间',
  rfstatisticsTime: '间隔时间',
  rfstatisticsTimePla: '请选择间隔时间',
  rfexecuteMethod: '执行方式',
  rfexecuteMethod1: '立即执行',
  rfexecuteMethod2: '周期执行',
  rffrequency: '周期',
  rffrequencyPla: '周几',
  rfselectedDayOfMonth: '第几天',
  rfdailyTimePla: '请选择时间',
  rfexecuteDevice: '执行设备',
  rfexecuteDevicePla: '请选执行设备',
  rfcodeSetListPla: '请选择属性',
  rfdesc: '描述',
  rfdescPla: '请输入你的备注',
  rfYes: '确定',
  rfNo: '取消',
  rfdataType1: '原始数据',
  rfdataType2: '聚合数据',
  rftimeOps1: '最近一天',
  rftimeOps2: '最近一个月',
  rftimeOps3: '最近三个月',
  rftimeOps4: '最近一年',
  rftimeOps5: '自定义',
  rftimeOps6: '最近一周',
  rfweek1: '周一',
  rfweek2: '周二',
  rfweek3: '周三',
  rfweek4: '周四',
  rfweek5: '周五',
  rfweek6: '周六',
  rfweek7: '周日',
  rffrequencyOps1: '每天',
  rffrequencyOps2: '每周',
  rffrequencyOps3: '每月',
  rfselect: '请选择',
  rfenter: '请输入',
  rview: '报表查看',
  rchart: '历史趋势图',
  rexport: '报表导出',
  rdelete: '删除',
  rexecutionStatus: '执行状态',
  rexecutionTime: '执行时间',
  rdelTip: '您确定要删除这个报表吗',
  fileName: '文件名称',
  fpreview: '预览',
  fdownload: '下载',
  fdelete: '删除',
  fcreateDate: '上报时间',
  fileSize: '文件大小',
  fdelTip: '您确定要删除这个文件吗',
  previewTitle: '预览',
  videoName: '名称',
  videoNew: '新增媒体流',
  vname: '名称',
  vnamePla: '请输入名称',
  vType: '平台类型',
  vTypePla: '请选平台类型',
  vprotocolPals: '部署环境',
  vaddress: '平台地址',
  vaddressPla: '请输入平台地址',
  vnumber: '摄像头编号',
  vnumberPla: '请输入摄像头编号',
  vdelTip: '您确定要删除这个媒体流吗',
  vnewTitle: '新建流媒体',
  veditTitle: '编辑流媒体',
  vkey: '用户Key',
  vkeyPla: '请输入用户Key',
  vappSecret: '用户秘钥',
  vappSecretPla: '请输入用户密钥',
  vconfirm: '确定',
  vcancel: '取消',
  vselect: '请选择',
  venter: '请输入',
  bnew: '创建组件',
  bdelTip: '您确定要删除这个看板吗',
  bedit: '编辑',
  bdel: '删除',
  bplay: '播放',
  bscreenshotBtn: '截屏按钮',
  bsoundBtn: '声音按钮',
  brecordBtn: '录制按钮',
  bhdBtn: '清晰度切换按钮',
  bwebExpend: '网页全屏按钮',
  bexpendBtn: '全局全屏按钮',
  bupdateTime: '更新时间',
  bfnewTitle: '新建组件',
  bfeditTitle: '编辑组件',
  bfname: '组件名称',
  bfnamePla: '请输入组件名称',
  bfType: '组件类型',
  bfTypePla: '请选择组件类型',
  bfdataConfig: '资源配置',
  bfvideoType: '数据源',
  bfvideoTypePla: '视频类型',
  bfvideoIdPla: '请选择组视频源',
  bflocationId: '数据源{text}',
  bflocationIdPla: '请选择',
  bfdevicePla: '请选择设备',
  bfadd: '添加数据源',
  bfcodesetPla: '请选择属性',
  bfreport: '报表名称',
  bfreportPla: '请选择报表',
  bftext: '文本组件',
  bfinstrument: '仪表组件',
  bfvideo: '视频组件',
  bflocation: '定位组件',
  bfstatistics: '统计组件',
  bfdataConfMsg: '请添加数据源',
  bfdeviceReMsg: '设备重复，请重新选择',
  bfenter: '请输入',
  bfselect: '请选择',
  bfstream: '流媒体',
  bflatlng: '经纬度',
  vtypeTable: '列表模式',
  vtypeCard: '分屏模式',
}
