export default {
  btnAdd: '添加',
  btnEdit: '编辑',
  btnDelete: '删除',
  delTip: '请确认是否删除组织？',
  name: '组织名称',
  topOrg: '上级组织',
  desc: '组织描述',
  sort: '排序',
  btnCancel: '取消',
  btnYes: '确定',
  newTitle: '新建组织',
  editTitle: '修改组织',
  enter: '请输入',
  enterMsg: '请输入数字',
  select: '请选择',
}
