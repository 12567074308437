export default {
  fileType: 'File Type',
  contentType: 'Content Type',
  collectionTime: 'Collection Time',
  fileText: 'Text',
  fileImage: 'Image',
  fileVideo: 'Video',
  fileAudio: 'Audio',
  rareAnimals: 'Rare animals',
  fire: 'Fire',
  latlng: 'Lat & Lng',
  deviceSN: 'Device SN',
}
