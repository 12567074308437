export default {
  home: 'Home',
  deviceManage: 'Device Management',
  deviceInfo: 'Device List',
  deviceLocation: 'Device Distribution',
  deviceTrail: 'Device Track',
  deviceLocus: 'Electronic Fence',
  deviceMsg: 'Device Message',
  deviceLog: 'Device Log',
  dataManage: 'Data Center',
  dataHistory: 'Historical Data',
  cloudManage: 'Could Development',
  cloudAccount: 'API Account',
  cloudPush: 'Message Push',
  cloudApiList: 'API List',
  warningManage: 'Alarm Center',
  warningTactics: 'Alarm Policy',
  warningContact: 'Alarm Contact',
  warningPush: 'Alarm Push',
  warningMsg: 'Alarm Message',
  configManage: 'System Configuration',
  configUser: 'User Management',
  configOrgManage: 'Organization Management',
  configLog: 'Diagnostic Log',
  deviceData: 'Device Data',
  deviceEdit: 'Edit Device',
  deviceNew: 'New Device',
  bigScreen: 'Big Screen',
  bigScreenFarm: 'Farm Screen',
  bigScreenPark: 'Park Screen',
  auth: 'No permission',
  kanban: 'Billboard',
  reportConfiguration: 'Report Configuration',
  reportQuery: 'Report Query',
  fileQuery: 'File Query',
  videoMonitoring: 'Video Monitoring',
  rolesConfiguration: 'Roles Management',
  siteManagement: 'Site Management',
  siteList: 'Site List',
  siteDistribution: 'Site Distribution',
  banner1:
    'Query historical data of devices, supports querying historical trend graphs by time period, supports data export.',
  banner2:
    'You can manage devices, configure device parameters, and associate sub-devices.',
  banner3:
    'Set rules for triggering alarms, supports rule combinations, set times for triggering alarms, can enable or disable alarms.',
  banner4:
    'View and handle generated alarm messages, can filter by alarm name, type, level, status, and time.',
  banner5: 'Manage alarm contacts',
  banner6: 'Set alarm contacts and push methods, supports email or SMS.',
  banner7: 'You can manage users and support associating roles.',
  banner8:
    'You can query diagnostic logs, search by tenant, device name, log ID, log type, satellite ID, collection time, and report time, supports data export.',
  banner9: 'Manage organizational structure, support multi-level organization.',
}
