import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 日志类型
export const queryDeviceLogType = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/log/type/query',
  })
}

// 产品类型
export const queryProdtType = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/manage/prodt/type/query',
  })
}

// 报表设备统计纬度
export const queryDevicefreqType = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/report/devicefreq/type/query',
  })
}

// 报表设备属性类型
export const queryDeviceattrType = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/report/deviceattr/type/query',
  })
}

// 告警类别
export const queryStrategyTriggerType = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/strategy/trigger/type/query',
  })
}

// 传感器指标类型
export const queryStrategySensorType = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/strategy/sensor/type/query',
  })
}

// 运算符类型
export const queryStrategyOperatorType = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/alarm/strategy/operator/type/query',
  })
}

// 消息上报消息图表类型
export const querySummarizeDeviceMsgType = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/summarize/device/msg/type/query',
  })
}
