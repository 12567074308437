export default {
  deviceTotal: '设备总数',
  activeDeviceTotal: '已激活',
  onlineDeviceTotal: '在线设备',
  deviceUpMsgTotal: '上报消息量',
  unit1: '台',
  unit2: '条',
  nav1: '设备列表',
  nav2: '设备分布',
  nav3: '轨迹回放',
  nav4: '告警消息',
  deviceActiveTotal: '已激活',
  deviceTotalNumber: '总数',
  lastMonth: '上月末',
  deviceTrends: '设备趋势',
  msgReportTrends: '消息上报趋势',
  nav: '快捷导航',
  notice: '通知公告',
  sOnline: '在线',
  sOffline: '离线',
  sUnActivated: '待激活',
  deviceTypeMulti: '多模',
  deviceTypeSingle: '单模',
  t1: '设备数量统计',
  t2: '设备状态统计',
}
