import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 查询所有电子围栏
export const queryLocusAoiAll = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/aoi/query/all',
    params,
  })
}

// 添加电子围栏
export const locusAoiAdd = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/aoi/add',
    params,
  })
}

// 查询电子围栏关联设备
export const queryLocusAoiDevice = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/aoidevice/query',
    params,
  })
}
// 查询电子围栏基本信息
export const queryLocusAoiBaseInfo = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/aoibase/query',
    params,
  })
}
// 修改电子围栏坐标范围
export const locusAoiUpdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/aoi/update',
    params,
  })
}
// 修改电子围栏基本信息
export const locusAddorupdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/aoidevice/addorupdate',
    params,
  })
}
// 批量删除电子围栏
export const locusDelete = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/aoi/delete',
    params,
  })
}
