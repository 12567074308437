import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 查询密钥
export const appSecretQuery = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/open/appSecret/query',
  })
}

//创建秘钥
export const appSecretAdd = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/open/appSecret/add',
  })
}

//删除秘钥
export const appSecretDelete = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/open/appSecret/delete',
  })
}
//更新秘钥
export const appSecretUpdate = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/open/appSecret/update',
  })
}
//查询通知地址
export const sendbackQuery = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/open/sendback/query',
  })
}
//添加通知地址
export const sendbackAddOrUpdate = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/open/sendback/addOrUpdate',
    params,
  })
}
//测试通知地址
export const sendbackTesturl = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/open/sendback/testurl',
    params,
  })
}
//删除通知地址
export const sendbackDelete = async () => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/open/sendback/delete',
  })
}
