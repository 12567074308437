export default {
  labelTime: '日期时间',
  timeStart: '开始时间',
  timeEnd: '结束时间',
  labelType: '告警类型',
  autoFetch: '自动刷新',
  autoFetchTip: '5s自动刷新',
  colTime: '时间',
  colName: '告警名称',
  colMsg: '告警消息',
  colStatus: '通知状态',
  colPhone: '手机号',
  colEmail: '邮箱',
  StatusSuccess: '成功',
  statusFail: '失败',
  btnSearch: '查询',
  deviceName: '设备名称',
  triggername: '告警场景',
  alarmLevel: '告警级别',
  triggerType: '告警类型',
  timeRange: '时间范围',
  startTime: '开始时间',
  endTime: '结束时间',
  triggerCondition: '告警场景',
  triggerDevice: '告警设备',
  alarmTime: '告警时间',
  pushStatus: '推送状态',
  noData: '暂无数据',
  msgTitleSensorAlarm: '传感器阈值告警',
  msgTitleAoiAlarm: '电子围栏告警',
  deviceAlarm: '设备告警',
  releaseAlarm: '解除告警',
  releaseAlarmTip: '你确定要解除这个告警吗?',
  eventAlarm: '事件告警',
  msgTitleSensorIncreAlarm: '传感器增量告警',
}
