export default {
  deviceName: 'Device Name',
  deviceNamePla: 'Please select a device',
  subDevice: 'Sub-device',
  subDevicePla: 'Please select a sub-device',
  varList: 'Sub-device variables',
  varListPla: 'Please select sub-device variables',
  range: 'Time Period',
  startTime: 'Start time',
  endTime: 'End time',
  deviceInfo: 'Device Information',
  deviceMap: 'Device Map',
  historyChart: 'Historical Trend Chart',
  selectPla: 'Please select',
  historyData: 'Historical data',
  msgRangeTime: 'The time span must be less than one year',
  noData: 'No data yet',
  max: 'Highest ',
  min: 'Minimum ',
  btnExport: 'Export to excel',
  downloading: 'Downloading',
  varName: 'Variable',
  varMsg: 'Please select variable',
  rangeMsg: 'Please select',
  chartTitle: 'Historical Trends',
  realData: 'Real-time data',
  aggregatedData: 'Aggregated data',
  maximum: 'Maximum',
  aggregatedConditions: 'Conditions',
  timeStep: 'Time interval',
  minute: 'minute',
  h: 'hour',
  d: 'day',
  month: 'month',
  y: 'year',
  condition1: 'Maximum and minimum values',
  condition2: 'Maximum value',
  condition3: 'Minimum value',
  condition4: 'Average value',
  condition5: 'Sum value',
  condition6: 'Counting value',
  condition7: 'Difference value',
  enter: 'Enter',
  select: 'Select',
  search: 'Query',
  reportName: 'report Name',
  reportConfNew: 'New',
  executionDevice: 'Execution Device',
  viewDevice: 'View Device',
  viewTitle: 'Execute devices and properties',
  reportStatus: 'Status',
  reportStatus1: 'Enable',
  reportStatus0: 'Close',
  operation: 'Operation',
  device: 'Device',
  var: 'Attribute',
  yes: 'Confirm',
  delete: 'Delete',
  dateType: 'Date Type',
  executionType: 'Execution Type',
  confDelTip: 'Are you sure you want to delete this configuration?',
  rffTitle: 'New Report',
  rffTitleEdit: 'Edit Report',
  rfName: 'Report Name',
  rfNamePla: 'Select',
  rfDateType: 'Data Type',
  rfDateTypePla: 'Select',
  rfStatisticsType: 'Conditions',
  rfStatisticsTypePla: 'Select',
  rflimit: 'Limit',
  rflimitPla: 'Enter',
  rflatestTime: 'Latest Time',
  rflatestTimePla: 'Select',
  rfstatisticsTime: 'Interval Time',
  rfstatisticsTimePla: 'Select',
  rfexecuteMethod: 'Execution Method',
  rfexecuteMethod1: 'Immediately',
  rfexecuteMethod2: 'Cycle',
  rffrequency: 'Cycle',
  rffrequencyPla: 'Day of the week',
  rfselectedDayOfMonth: 'What day is it',
  rfdailyTimePla: 'Select',
  rfexecuteDevice: 'Execution Device',
  rfexecuteDevicePla: 'Select',
  rfcodeSetListPla: 'Select',
  rfdesc: 'Describe',
  rfdescPla: 'Enter',
  rfYes: 'Confirm',
  rfNo: 'Cancel',
  rfdataType1: 'Real-time data',
  rfdataType2: 'Aggregate data',
  rftimeOps1: 'Last day',
  rftimeOps2: 'Last month',
  rftimeOps3: 'Last three months',
  rftimeOps4: 'Last year',
  rftimeOps5: 'Custom',
  rftimeOps6: 'Last week',
  rfweek1: 'Monday',
  rfweek2: 'Tuesday',
  rfweek3: 'Wednesday',
  rfweek4: 'Thursday',
  rfweek5: 'Friday',
  rfweek6: 'Saturday',
  rfweek7: 'Sunday',
  rffrequencyOps1: 'Every day',
  rffrequencyOps2: 'Every week',
  rffrequencyOps3: 'Every month',
  rfselect: 'Select',
  rfenter: 'Enter',
  rview: 'View Data',
  rchart: 'View Chart',
  rexport: 'Export',
  rdelete: 'Delete',
  rexecutionStatus: 'Execution Status',
  rexecutionTime: 'Execution Tme',
  rdelTip: 'Are you sure you want to delete this report?',
  fileName: 'File Name',
  fpreview: 'Preview',
  fdownload: 'Download',
  fdelete: 'Delete',
  fcreateDate: 'Reporting Time',
  fileSize: 'File Size',
  fdelTip: 'Are you sure you want to delete this file?',
  previewTitle: 'Preview',
  videoName: 'Name',
  videoNew: 'New',
  vname: 'Name',
  vnamePla: 'Enter',
  vType: 'Platform Type',
  vTypePla: 'Select',
  vprotocolPals: 'Environment',
  vaddress: 'Platform Address',
  vaddressPla: 'Enter',
  vnumber: 'Camera Number',
  vnumberPla: 'Enter',
  vdelTip: 'Are you sure you want to delete this media stream?',
  vnewTitle: 'New streaming media',
  veditTitle: 'Edit streaming media',
  vkey: 'User Key',
  vkeyPla: 'Enter',
  vappSecret: 'User Secret',
  vappSecretPla: 'Enter',
  vconfirm: 'Confirm',
  vcancel: 'Cancel',
  vselect: 'Select',
  venter: 'Enter',
  bnew: 'New',
  bdelTip: 'Are you sure you want to delete this component?',
  bedit: 'Edit',
  bdel: 'Delete',
  bplay: 'Play',
  bscreenshotBtn: 'Screenshot',
  bsoundBtn: 'Sound',
  brecordBtn: 'Record',
  bhdBtn: 'HD',
  bwebExpend: 'Web Expend',
  bexpendBtn: 'Expend',
  bupdateTime: 'Update Time',
  bfnewTitle: 'New component',
  bfeditTitle: 'Edit component',
  bfname: 'Component Name',
  bfnamePla: 'Enter',
  bfType: 'Component Type',
  bfTypePla: 'Select',
  bfdataConfig: 'Resource Allocation',
  bfvideoType: 'Data Source',
  bfvideoTypePla: 'Video Type',
  bfvideoIdPla: 'Select',
  bflocationId: 'NO.{text}',
  bflocationIdPla: 'Select',
  bfdevicePla: 'Device',
  bfadd: 'Add Source',
  bfcodesetPla: 'Attribute',
  bfreport: 'Report Name',
  bfreportPla: 'Select',
  bftext: 'Text Component',
  bfinstrument: 'Instrument Component',
  bfvideo: 'Video Component',
  bflocation: 'Positioning Components',
  bfstatistics: 'Statistics Component',
  bfdataConfMsg: 'Please add a data source',
  bfdeviceReMsg: 'Device duplicate, please reselect',
  bfenter: 'Enter',
  bfselect: 'Select',
  bfstream: 'Streaming',
  bflatlng: 'Lat & Lng',
  vtypeTable: 'List Mode',
  vtypeCard: 'Screen Mode',
}
