import { createApp } from 'vue'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'
import App from './App.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import '@/styles/base.scss'
import i18n from '@/i18n'
import router from './router'

import 'leaflet/dist/leaflet.css'

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

const wx_lan = localStorage.getItem('wx_lan') || 'cn'
i18n.global.locale = wx_lan

document.title = wx_lan === 'cn' ? '天数AIoT平台' : 'TianShu AIoT Platform'

app.use(ElementPlus, {
  locale: wx_lan === 'cn' ? zhCn : en,
})

app.use(i18n)
app.use(createPinia())
app.use(router)
app.mount('#app')
