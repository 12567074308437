export default {
  baseInfo: 'Device information',
  orgName: 'Organization',
  productType: 'Product appertained',
  sn: 'SN',
  id: 'ID',
  deviceAddress: 'Device location',
  deviceMap: 'Device Map',
  varTitle: 'Variable Overview',
  labelSubDevice: 'sub-device',
  btnSearch: 'Query',
  btnColloct: 'Active collection',
  btnHistory: 'History query',
  colattributeName: 'variable name',
  connectModel: 'Connection method',
  currentValue: 'Current value',
  unit: 'Unit',
  prodTime: 'Collection time',
  operation: 'Operate',
  collectTitle: 'Prompt',
  collectTip: 'The collection command has been issued',
  btnYes: 'OK',
  attribute: 'Attribute',
  subDevice: 'Sub-Device',
  alarmMsg: 'Alarm Message',
  status: 'Status',
  latLng: 'Lat & Lng',
  createTime: 'Create Time',
  noData: 'Not Data',
  updateTime: 'Update time',
  data: 'Data',
  index: 'Index',
  deviceName: 'Sub-device Name',
  producer: 'Producer',
  brand: 'Brand/Model',
  deviceSn: 'Port/SN',
  variable: 'Variable',
  commandIssuance: 'Execute command',
  commandRecord: 'Record',
  commandSelectlabel: 'Configuration Command',
  execute: 'Execute',
  cancel: 'Cancel',
  brandCol: 'Brand & model',
  port: 'Port',
  subSn: 'Sub device SN',
  getawaySn: 'Gateway SN',
  response: 'Current response',
  instructOutput: 'Device response',
  commandIng: 'Sending instructions',
  commandSuccess: 'Command sent successfully',
  wait: 'Wait（{count}/{MAX_TIME_OUT}）',
  timeOut:
    'The maximum number of retries has been reached, and the device is not responding. Please try again',
  responseRecive: 'Received response',
  startTime: 'Start time',
  endTime: 'End time',
  query: 'Query',
  close: 'CLose',
  executeTime: 'Execute time',
  instructName: 'instruc name',
  repStatus: 'Response status',
  repText: 'Response content',
}
