export default {
  title: 'Device message push',
  tip: 'The device message push URI is also called the online push identifier. It is a specific URI used to push device messages online, which contains the device ID, push protocol and some other parameters, allowing the system or application to easily send push messages to the device. The URL needs to support POST requests. ',
  btnTest: 'Test',
  btnSave: 'Save',
  btnDel: 'Delete',
  delTip:
    'After deleting, you will not be able to receive message pushes, are you sure to delete? ',
  delTitle: 'Delete Tip',
  saveSuccess: 'Save push URL successfully',
  saveError: 'Failed to save push URL',
  testSuccess: 'Test passed',
  testError: 'Test failed',
  delSuccess: 'Delete successful',
  delError: 'Delete failed',
  btnCancel: 'Cancel',
  notifyUrlPla: 'please enter HTTP/HTTPS URI',
  enter: 'Enter',
}
