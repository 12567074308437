export default {
  labelName: 'Alarm Push Name',
  labelStrategy: 'Alarm Policy',
  btnSearch: 'Query',
  btnReset: 'Reset',
  btnNew: 'Add alarm  push',
  colName: 'Alarm Push Name',
  colTriggerName: 'Alarm Policy',
  colPushType: ' Push mechanism',
  colContact: 'Alert Contact',
  colOperation: 'Operate',
  delTip: 'Are you sure you want to delete this alert  push? ',
  btnDel: 'Delete',
  btnEdit: 'Edit',
  btnView: 'View',
  actionTypeOnce: 'Only first time',
  actionTypeRepeat: 'Repeat',
  actionTypeStep: 'Interval',
  delSuccess: 'Delete successful',
  delError: 'Delete failed',
  labelPushName: 'Notification Name',
  labelTriggerId: 'Alarm Strategy',
  labelActionType: 'Push mechanism',
  labelActionInternal: 'Interval time',
  labelReachUserIds: 'Alarm Contacts',
  btnSave: 'Save',
  btnCancel: 'Cancel',
  time5Min: '5 minutes',
  time10Min: '10 minutes',
  time30Min: '30 minutes',
  time1H: '1 hour',
  time24H: '24 hours',
  titleView: 'View alarm push',
  titleEdit: 'Edit alarm push',
  titleNew: 'New alarm push',
  opSuccess: 'Operation succeeded',
  delTitle: 'Delete Tip',
  btnOk: 'Confirm',
  enterMsg: 'Please enter',
  selectMsg: 'Please select',
  enter: 'Enter',
  noData: 'No data yet',
  pushLabel: 'Push Time',
  pushOps1: 'Always',
  pushOps2: 'One minute',
  pushOps3: 'One day',
}
