// 系统管理
import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 分页查询-设备列表
export const devicePage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/manage/devicePage',
    params,
  })
}
// 查询所有设备
export const queryLocusTreeList = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/manage/treeList',
    params,
  })
}
// 添加设备
export const deviceAdd = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/manage/add',
    params,
  })
}
// 删除设备
export const deviceDelete = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/manage/delete',
    params,
  })
}
// 修改设备
export const deviceUpdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/manage/update',
    params,
  })
}
// 设备详情
export const deviceDetail = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/manage/detail',
    params,
  })
}

// 设备管理-设备汇总信息
export const summarizeDeviceInfo = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/summarize/device/info',
    params,
  })
}
// 分页查询-设备日志
export const logPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/log/page',
    params,
  })
}

// 查询所有设备
export const queryAllDevice = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/location/query/bycondit',
    params,
  })
}

// 查询设备默认地址
export const queryDeviceDefaultLocation = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/manage/query/defaultLocation',
    params,
  })
}
// 根据设备sn查询子设备列表
export const queryDeviceInfoByGatewayDeviceSn = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/thing/manage/getDeviceInfoByGatewayDeviceSn',
    params,
  })
}
// 根据设备id查询子设备列表
export const queryDeviceInfoByGatewayDeviceId = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/thing/manage/getDeviceInfoByGatewayDeviceId',
    params,
  })
}
// 根据传感器id，查询变量table
export const querySensorDeviceData = async (params?: any) => {
  return get({
    suffix: SUFFIX.TIANSHU,
    url: '/device/attr/sensorPhysicsView',
    params,
  })
}
// 根据子设备id查询物模型属性元数据
export const queryProductThingModelMetadata = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/thing/manage/getProductThingModelMetadata',
    params,
  })
}
// 根据子设备id查询物模型属性元数据 + 是否支持统计图表
export const queryDeviceAttr = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/attr/chartPhysicsBox',
    params,
  })
}

// 在线检测
export const deviceOnlinecheck = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/method/onlinecheck',
    params,
  })
}

// 主动采集
export const deviceMethodQueryProperties = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/method/queryProperties',
    params,
  })
}

//到处日志
export const exportLogData = async (params?: any) => {
  return post({
    suffix: SUFFIX.TIANSHU,
    url: '/device/log/export',
    params,
    userConfig: {
      responseType: 'arraybuffer',
      timeout: 100000,
    },
  })
}

// 获取网关及子设备最新设备属性
export const queryDevicePhysicsLastOnceList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/manage/physicsLastOnce/gatewayDeviceId/list',
    params,
  })
}

// 分页查询查询设备发货记录
export const queryDdviceDeliveryPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/manage/deliveryPage/query',
    params,
  })
}

// 数据中心-导出历史数据
export const exportPhysicsHistory = (params: any) => {
  return post({
    suffix: SUFFIX.TIANSHU,
    url: '/device/attr/physicsHistory/export',
    params,
    userConfig: {
      responseType: 'arraybuffer',
    },
  })
}

// 数据中心-历史数据
export const queryDeviceListPhysicsHistoryPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/attr/listPhysicsHistoryPage',
    params,
  })
}

// 数据中心-历史趋势图
export const queryDeviceChartPhysicsHistory = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/attr/chartPhysicsHistory',
    params,
  })
}
// 数据中心-历史趋势图 -实时
export const queryDeviceChartRealTimePhysicsHistory = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/attr/chartPhysicsRealTime',
    params,
  })
}

// 日志-消息来源
export const queryDeviceLogTransTreeList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/log/trans/treeList/query',
    params,
  })
}

// 日志-日志溯源
export const queryDeviceSourceLog = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/log/listDeviceLogById',
    params,
  })
}

// 保存属性呈现方式
export const updateDeviceAttrPresentMode = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/attribute/presentMode/save',
    params,
  })
}

// 获取已下发的指令信息 - 列表
export const queryDeviceInstructPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/instruct/info/page',
    params,
  })
}

//获取子设备指令
export const queryDeviceInstructSensor = (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/instruct/sensor/query',
    params,
  })
}

//下发子设备指令
export const executeDeviceInstruct = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/instruct/sensor/execute',
    params,
  })
}

//获取已下发的指令信息 - 单条
export const queryDeviceInstructInfo = (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/instruct/info/get',
    params,
  })
}

//分配设备到组织
export const sendDeviceToOrg = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/manage/sendToOrg',
    params,
  })
}

//设备定位批量
export const queryBatchDeviceLocation = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/locus/location/querybatch/bycondit',
    params,
  })
}
