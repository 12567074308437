export default {
  baseInfo: '设备信息',
  orgName: '所属组织',
  productType: '所属产品',
  sn: 'SN',
  id: 'ID',
  deviceAddress: '设备位置',
  deviceMap: '设备地图',
  varTitle: '属性',
  labelSubDevice: '子设备',
  btnSearch: '查询',
  btnColloct: '主动采集',
  btnHistory: '历史查询',
  colattributeName: '变量名称',
  connectModel: '连接方式',
  currentValue: '当前值',
  unit: '单位',
  prodTime: '采集时间',
  operation: '操作',
  colloctTitle: '提示',
  colloctTip: '采集指令已经下发',
  btnYes: '确定',
  attribute: '属性',
  subDevice: '子设备',
  alarmMsg: '告警记录',
  status: '状态',
  latLng: '经纬度',
  createTime: '创建时间',
  noData: '暂无数据',
  updateTime: '更新时间',
  data: '历史数据',
  index: '序号',
  deviceName: '子设备名称',
  producer: '厂家',
  brand: '品牌/型号',
  deviceSn: '端口号/SN',
  variable: '属性',
  commandIssuance: '命令下发',
  commandRecord: '下发记录',
  commandSelectlabel: '请选择配置命令',
  execute: '下发',
  cancel: '取消',
  brandCol: '品牌型号',
  port: '端口号',
  subSn: '子设备SN',
  getawaySn: '网关SN',
  response: '当前响应',
  instructOutput: '设备响应',
  commandIng: '指令发送中...',
  commandSuccess: '指令发送成功',
  wait: '等待响应（{count}/{MAX_TIME_OUT}）',
  timeOut: '已达到最大重试次数，设备未响应，请重试',
  responseRecive: '已收到响应',
  startTime: '开始时间',
  endTime: '结束时间',
  query: '查询',
  close: '关闭',
  executeTime: '下发时间',
  instructName: '指令内容',
  repStatus: '响应状态',
  repText: '响应内容',
}
