import md5 from 'md5'
import sha384 from 'crypto-js/sha384'
import { isEmpty } from 'lodash'
import { PRODUCT_TYPE } from '@/common'
import moment from 'moment'
import * as echarts from 'echarts'
import { ElMessageBox } from 'element-plus'
import i18n from '@/i18n'

const t = i18n.global.t

// 将数字中逗号分隔
export const transformNumber = (number: number) => {
  const reg = /(\d)(?=(?:\d{3})+$)/g
  return number.toString().replace(reg, '$1,')
}

// 明文加密，md5->sh384
export const encode = (input: string) => {
  return sha384(md5(input)).toString()
}

// 验证码加密，sh384
export const sha = (input: string) => {
  return sha384(input).toString()
}

// 将枚举转成数组下拉
export const mapToList = (map: any) => {
  return Object.keys(map).map((key: any) => {
    return {
      label: map[key],
      value: key,
    }
  })
}

// 将枚举接口返回数据转换成下拉选项
export const transformEnum = (list: any) => {
  return list.map((item: any) => {
    const { code, name } = item
    return {
      label: name,
      value: code,
    }
  })
}

// 转换日志内容
export const transformLogContent = (attrList: any, formatterMap?: any) => {
  return attrList.map(({ key, value, label }: any) => {
    return {
      label,
      value:
        formatterMap && formatterMap[key] ? formatterMap[key](value) : value,
      key,
    }
  })
}

// 获取表单时间
export const formatFormTime = (data: any, keyList: any) => {
  if (isEmpty(data)) {
    return
  }
  const [start, end] = data
  const [startKey, endKey] = keyList
  return {
    [startKey]: moment(start).valueOf(),
    [endKey]: moment(end).valueOf(),
  }
}

// 校验子设备信息必填
export const validateSubDevicesRequired = (
  subDeviceList: any,
  productType: any
) => {
  const validateKeys =
    PRODUCT_TYPE.SINGLE_GATEWAY === productType
      ? ['productKey', 'sensorId']
      : ['productKey', 'busType', 'busId', 'sensorId']
  return subDeviceList.some((item: any) => {
    return validateKeys.map((key: string) => Boolean(item[key])).includes(false)
  })
}

// 校验子设备是否重复
export const validateSubDevices = (
  index: number,
  subDeviceList: any,
  productType: any
) => {
  const validateKeys =
    PRODUCT_TYPE.SINGLE_GATEWAY === productType
      ? ['sensorId']
      : ['busType', 'busId', 'sensorId']
  const subDevice = subDeviceList[index]

  const hasEmptyKey = validateKeys
    .map((key: string) => Boolean(subDevice[key]))
    .includes(false)

  if (hasEmptyKey) {
    return false
  }

  const filterList = subDeviceList.filter((item: any, i: number) => i !== index)

  let totalFlag = false
  filterList.map((item: any) => {
    // 对比全部字段
    let flag = true
    validateKeys.map((key: string) => {
      if (subDevice[key] !== item[key]) {
        flag = false
      }
    })

    if (flag) {
      totalFlag = true
    }
  })

  return totalFlag
}

export const colorConf = [
  {
    color: 'rgba(129,226,255)',
    opacityColor: 'rgba(129,226,255,0.22)',
  },
  {
    color: 'rgba(100,162,255)',
    opacityColor: 'rgba(100,162,255,0.22)',
  },
  {
    color: 'rgba(255,211,100)',
    opacityColor: 'rgba(255,211,100,0.22)',
  },
  {
    color: 'rgba(64, 221, 174)',
    opacityColor: 'rgba(64, 221, 174,0.22)',
  },
  {
    color: 'rgba(255,172,50)',
    opacityColor: 'rgba(255,172,50,0.22)',
  },
  {
    color: 'rgba(255,55,91)',
    opacityColor: 'rgba(255,55,91,0.22)',
  },
]

export const getLineColor = (index: number) => {
  if (colorConf.length - 1 < index) {
    return {}
  }
  const { color, opacityColor } = colorConf[index]
  return {
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: opacityColor,
        },
        {
          offset: 1,
          color: 'rgba(255,255,255,0)',
        },
      ]),
    },
    lineStyle: {
      color,
    },
    itemStyle: {
      color,
    },
  }
}

// 下载文件
export const downLoadFile = ({ blob, fileName }: any) => {
  const objectUrl = URL.createObjectURL(blob) //创建下载的链接
  const a = document.createElement('a')
  a.href = objectUrl
  a.download = fileName //设置文件名
  a.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
  )
  window.URL.revokeObjectURL(blob) //释放bolb对象
}

// 删除公共提示框
export const msgbox = ({
  title = t('COMMON.delTitle'),
  tip,
  thenFn = () => {},
  catchFn = () => {},
}: any) => {
  ElMessageBox.confirm(tip, title, {
    confirmButtonText: t('COMMON.btnYes'),
    cancelButtonText: t('COMMON.btnCancel'),
    type: 'warning',
    autofocus: false,
  })
    .then(thenFn)
    .catch(catchFn)
}

export const getDate = (month: number, unit: any) => {
  const current = moment().format('YYYY-MM-DD')
  const preDate = moment(current).subtract(month, unit).format('YYYY-MM-DD')
  return [
    new Date(moment(preDate + ' 00:00:00').valueOf()),
    new Date(moment(current + ' 23:59:59').valueOf()),
  ]
}
