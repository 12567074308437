import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 添加-修改设备配置
export const deviceConfInfoUpdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/sky/deviceconf/addOrUpdate',
    params,
  })
}

//分页查询-设备列表
export const queryDeviceConfInfoPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/sky/deviceconf/page',
    params,
  })
}

//查询设备配置详情
export const queryDeviceConfInfoDetail = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/sky/deviceconf/query',
    params,
  })
}
