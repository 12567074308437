// 倒计时
export const COUNT_NUMBER = 60

// 页码配置
export const PAGE_SIZES = [10, 20, 50, 100, 200, 300, 400]

// 天地图tk-个人账号申请
export const MAP_TK = [
  'b81d3d4d43dd0268c375d2cdca61f2c82f3570094018cacd659dd58c597277ea',

  // 'c3bd64edefcf16cd0bd0eb9e80b6f5fe',
  // '23eb5773d86f1357c948e55a176fdf0e',
  // 'ba4d34030e82ccb5396766c4f7cc0560',
  // '964af57a1d8eff2476705b1f045e8068',
  // '270a62f89a146b3fd9d9b328769d8b70',
  // '9e09c3b84c2cac7e812f30ff4cfa5fab',
  // '35b1ef76dac2a893c933980b886f4daa',
  // 'a8ec501724203a88a38cd90c0bb2d788',
  // '196707f33e161c446d6f7d7c9d341e53',
  // '53c0a1c07dd75e36264064b7d1e13397',
]
// 天地图tk-企业账号
export const MAP_PRO_TK = [
  // '2bc1223038be43a67fa9dc990a946f1e',
  // '57722085fb4b43e3f94b70034af1ef7e',
  'd6eaab062c674da1997ea839d2de78ab3e575b6b28d010aa92827663fcba9902',
  // 'badd12c810dbec9944af1be55672c0f3',
  // '2bc1223038be43a67fa9dc990a946f1e',
]
