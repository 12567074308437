export default {
  deviceName: 'Device Name',
  deviceNamePla: 'Please enter the device name',
  deviceSn: 'SN',
  productType: 'Product Appertained',
  deviceStatusCode: 'Status',
  deviceStatusCodePla: 'Please select',
  btnAdd: 'Add',
  deviceStatusName: 'Status',
  onlineCheck: 'Online Check',
  btnEdit: 'Edit',
  btnDelete: 'Delete',
  btnDetail: 'Detail',
  btnView: 'Data View',
  noData: 'No data yet',
  deviceDeleteTitle: 'Delete Tip',
  deviceDeleteTip:
    'Deleting the device will delete the associated configuration relationship, but it will not affect the data associated with this device. Please confirm if you want to delete it?',
  btnCancel: 'Cancel',
  labelNoactive: 'Inactivated',
  labelActive: 'Activated',
  labelOffline: 'Offline',
  labelOnline: 'Online',
  colName: 'Device Name',
  colSn: 'Device SN',
  colOrg: 'Organization',
  colProduct: 'Product Appertained',
  colAddress: 'Device Address',
  colCreateTime: 'Creation Time',
  delSuccessMsg: 'The device was deleted successfully',
  delErrorMsg: 'Delete device failed',
  commondTip: 'The acquisition command has been issued',
  commonTitle: 'Prompt',
  btnYes: 'OK',
  colOperation: 'Operate',
  accessMethod: 'Access Method',
  deviceLabel: 'Device Label',
  belogingSite: 'Belonging Site',
  latLng: 'Lat & Lng',
  deviceIcon: 'Device Icon',
}
