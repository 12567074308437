export default {
  deviceName: 'Device Name',
  deviceNamePla: 'Device Name',
  logId: 'Log ID',
  deviceSn: 'Device SN',
  logType: 'Log Type',
  newText: 'Data updated within 1 minute',
  autoFetchText: 'Auto refresh',
  autoFetchTip: 'Automatically refresh every five seconds',
  transType: 'Source',
  collectTime: 'Collection Time',
  createTime: 'Log Time',
  noData: 'Currently no data',
  logContent: 'Log Content',
  lat: 'Lat',
  lng: 'Lng',
  satelliteId: 'Satellite ID',
  startTime: 'Start Time',
  endTime: 'End Time',
  onboardTime: 'Onboard Time',
  exportExcel: 'Export',
  parseStatus: 'Parsing Status',
  logTraceability: 'Log Traceability',
}
