export default {
  add: '添加',
  edit: '编辑',
  device: '设备',
  baseInfo: '基本信息',
  deviceName: '设备名称',
  deviceNamePla: '请输入设备名称',
  deviceSn: '设备SN',
  deviceSnPla: '请输入设备SN',
  deviceConfig: '设备配置',
  labelData: '数据上传',
  varTempalte: '变量模板',
  btnSearch: '查询',
  dataUnresolve: '透传',
  deviceLocation: '设备定位',
  autoLocation: '自动定位',
  handLocation: '手动定位',
  deviceMap: '设备地图',
  map: '地图',
  btnSave: '保存',
  btnCancel: '取消',
  msgSelect: '请选择',
  deviceMsg: '请输入设备名称',
  deviceSnMsg: '请输入设备SN',
  msgSuccess: '操作成功',
  address: '详细地址',
  subDeviceNum: '子设备数量',
  detail: '详情',
  productType: '类型',
  ame: '名称/型号',
  producer: '品牌',
  connectType: '连接方式',
  varName: '变量名',
  unit: '单位',
  range: '范围',
  dataType: '数据类型',
  lng: '经度',
  lat: '纬度',
  preview: '预览',
  btnYes: '确定',
  msgAddress: '请输入详细地址',
  msgLng: '请输入正确的经度',
  msgLat: '请输入正确的纬度',
  varCount: '变量数量',
  operation: '操作',
  subDevice: '子设备',
  relativeSub: '关联子设备',
  relativeSubNum: '已关联{text}子设备',
  repeatPort: '端口号重复',
  select: '请选择',
  enter: '请输入',
  msg1: '请输入产品名/厂家/型号',
  msg2: '请输入总线类型',
  msg3: '请输入总线ID',
  msg4: '请输入传感器ID',
  btnAdd: '增加子设备',
  subErrorTip: '子设备信息有误，请进行核对',
  productMsg: '请先选择所属产品',
  deviceSnAliasPla: '传感器SN（非必填）',
  chooseValue: '可选值为:',
  latLngType1: '度分秒',
  latLngType2: '小数',
  formatterErr: '格式不匹配',
}
