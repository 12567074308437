export default {
  platformName: '天数AIoT平台',
  serviceError: '服务异常',
  loginOuttime: '登录已过期',
  satelliteMap: '卫星图',
  streetMap: '街道图',
  terrainMap: '地形图',
  btnCancel: '取消',
  btnYes: '确认',
  timeSelect1: '今天',
  timeSelect2: '最近一个月',
  timeSelect3: '最近三个月',
  timeSelect4: '最近半年',
  timeSelect5: '最近一年',
  chinese: '中文',
  english: '英文',
  btnSearch: '查询',
  btnReset: '重置',
  delTitle: '删除提示',
  changeImg: '更换照片',
  previewImg: '图片预览',
  uploadImgErr: '上传失败，请重新上传',
}
