import { createI18n } from 'vue-i18n'
import CN from './cn'
import EN from './en'

const i18n: any = createI18n({
  locale: 'en',
  messages: {
    cn: {
      ...CN,
    },
    en: {
      ...EN,
    },
  },
})

export default i18n
