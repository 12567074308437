import { createRouter, createWebHashHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'

export const routes: any = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '首页',
      enTitle: 'Home',
      white: true,
    },
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: false,
      white: true,
    },
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      layout: false,
      white: true,
    },
    component: () => import('@/views/test/index.vue'),
  },
  {
    path: '/backdoor',
    name: 'backdoor',
    meta: {
      layout: true,
      white: true,
      title: '设备配置',
      enTitle: 'Device Config',
    },
    component: () => import('@/views/backdoor/index.vue'),
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    meta: {
      layout: false,
      white: true,
    },
    component: () => import('@/views/forget-password/index.vue'),
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      title: '暂无权限',
      enTitle: 'No permission',
      white: true,
    },
    component: () => import('@/views/auth/index.vue'),
  },
  {
    path: '/statistics',
    name: 'statistics',
    meta: {
      title: '数据中心',
      enTitle: 'Data Center',
      code: '2',
    },
    redirect: '/statistics/board',
    children: [
      {
        path: 'board',
        name: 'board',
        meta: {
          title: '看板',
          enTitle: 'Billboard',
          code: '2-1',
          banner: '可自定义数据组件，支持数据源配置',
          enBanner:
            'Customizable data components, supporting data source configuration',
        },
        component: () => import('@/views/statistics/board/index.vue'),
      },
      {
        path: 'report-config',
        name: 'report-config',
        meta: {
          title: '报表配置',
          enTitle: 'Report Configuration',
          code: '2-3',
          banner:
            '可自定义报表，支持原始数据统计和聚合数据统计，支持立即查询和周期查询',
          enBanner:
            'Customizable reports, supporting raw data statistics and aggregated data statistics, supporting immediate and periodic queries',
        },
        component: () => import('@/views/statistics/report-config/index.vue'),
      },
      {
        path: 'report',
        name: 'report',
        meta: {
          title: '报表查询',
          enTitle: 'Report Query',
          code: '2-4',
          banner: '可查询报表，支持历史趋势图',
          enBanner: 'Can query reports and support historical trend charts',
        },
        component: () => import('@/views/statistics/report/index.vue'),
      },
      {
        path: 'files',
        name: 'files',
        meta: {
          title: '文件查询',
          enTitle: 'File Query',
          code: '2-5',
          banner: '可收取文件数据，支持预览和下载',
          enBanner: 'Can collect file data, supports preview and download',
        },
        component: () => import('@/views/statistics/file/index.vue'),
      },
      {
        path: 'videos',
        name: 'videos',
        meta: {
          title: '视频监控',
          enTitle: 'Video Monitoring',
          code: '2-6',
          banner: '可查看视频监控画面，支持分屏显示和全屏显示',
          enBanner:
            'Can view video surveillance footage, supporting split screen and full screen display',
        },
        component: () => import('@/views/statistics/videos/index.vue'),
      },
      {
        path: 'bigscreen-farm',
        name: 'bigscreen-farm',
        meta: {
          title: '牧业大屏',
          enTitle: 'Big Screen',
          code: '2-2',
        },
        component: () => import('@/views/big-screen/farm/index.vue'),
      },
      {
        path: 'bigscreen-park',
        name: 'bigscreen-park',
        meta: {
          title: '森林公园大屏',
          enTitle: 'Big Screen',
          code: '2-7',
        },
        component: () => import('@/views/big-screen/park/index.vue'),
      },
    ],
  },
  {
    path: '/device',
    name: 'device',
    meta: {
      title: '设备管理',
      enTitle: 'Device Management',
      code: '1',
    },
    redirect: '/device/device-list',
    children: [
      {
        path: 'device-list',
        name: 'device-list',
        meta: {
          title: '设备列表',
          enTitle: 'Device List',
          code: '1-1',
        },
        component: () => import('@/views/device/index.vue'),
        redirect: '/device/device-list',
        children: [
          {
            path: '',
            name: 'device-index',
            meta: {
              title: '设备列表',
              enTitle: 'Device List',

              code: '1-1',
              banner: '可管理设备，可配置设备的参数，可关联子设备。',
              enBanner:
                'You can manage devices, configure device parameters, and associate sub-devices.',
            },
            component: () => import('@/views/device/list/index.vue'),
          },
          {
            path: 'device-data',
            name: 'device-data',
            meta: {
              title: '设备详情',
              enTitle: 'Device Detail',
              code: '1-1',
            },
            component: () => import('@/views/device/data/index.vue'),
          },
          {
            path: 'device-edit',
            name: 'device-edit',
            meta: {
              title: '编辑设备',
              enTitle: 'Edit Device',
              code: '1-1',
            },
            component: () => import('@/views/device/edit/index.vue'),
          },
        ],
      },
      {
        path: 'device-location',
        name: 'device-location',
        meta: {
          title: '设备分布',
          enTitle: 'Device Location',

          code: '1-2',
        },
        component: () => import('@/views/device/location/index.vue'),
      },
      {
        path: 'device-trail',
        name: 'device-trail',
        meta: {
          title: '轨迹回放',
          enTitle: 'Device Track',
          code: '1-3',
        },
        component: () => import('@/views/device/trail/index.vue'),
      },
      {
        path: 'device-locus',
        name: 'device-locus',
        meta: {
          title: '电子围栏',
          enTitle: 'Electronic Fence',
          code: '1-4',
        },
        component: () => import('@/views/device/locus/index.vue'),
      },
    ],
  },
  {
    path: '/cloud',
    name: 'cloud',
    meta: {
      title: '云开发',
      enTitle: 'Coud Development',
      code: '5',
    },
    redirect: '/cloud/account',
    children: [
      {
        path: 'account',
        name: 'account',
        meta: {
          title: 'API账户',
          enTitle: 'API Account',
          code: '5-1',
        },
        component: () => import('@/views/cloud/account/index.vue'),
      },
      {
        path: 'message',
        name: 'message',
        meta: {
          title: '消息推送',
          enTitle: 'Message Push',
          code: '5-1',
        },
        component: () => import('@/views/cloud/message/index.vue'),
      },
      {
        path: 'api-list',
        name: 'api-list',
        meta: {
          title: 'API列表',
          enTitle: 'API List',

          code: '5-1',
        },
        component: () => import('@/views/cloud/api-list/index.vue'),
      },
    ],
  },
  {
    path: '/warning',
    name: 'warning',
    meta: {
      title: '通知告警',
      enTitle: 'Alarm Center',

      code: '3',
    },
    redirect: '/warning/tactics',
    children: [
      {
        path: 'tactics',
        name: 'tactics',
        meta: {
          title: '告警策略',
          enTitle: 'Alarm Policy',
          code: '3-1',
          banner:
            '可设置触发告警的规则，支持规则组合，可设置告警触发的时间，可开启和关闭告警。',
          enBanner:
            'Set rules for triggering alarms, supports rule combinations, set times for triggering alarms, can enable or disable alarms.',
        },
        component: () => import('@/views/warning/tactics/index.vue'),
      },
      {
        path: 'warning-message',
        name: 'warning-message',
        meta: {
          title: '告警消息',
          enTitle: 'Alarm Message',
          code: '3-2',
          banner:
            '可查看和处理产生的告警消息，可根据告警的名称、类型、级别、状态、时间进行过滤',
          enBanner:
            'View and handle generated alarm messages, can filter by alarm name, type, level, status, and time.',
        },
        component: () => import('@/views/warning/message/index.vue'),
      },
      {
        path: 'contact',
        name: 'contact',
        meta: {
          title: '告警联系人',
          enTitle: 'Alarm Contact',
          code: '3-3',
          banner: '可管理告警联系人。',
          enBanner: 'Manage alarm contacts',
        },
        component: () => import('@/views/warning/contact/index.vue'),
      },
      {
        path: 'push',
        name: 'push',
        meta: {
          title: '告警推送',
          enTitle: 'Alarm Push',
          code: '3-3',
          banner: '可设置告警联系人和推送方式，支持邮件或短信两种推送方式。',
          enBanner:
            'Set alarm contacts and push methods, supports email or SMS.',
        },
        component: () => import('@/views/warning/push/index.vue'),
      },
    ],
  },
  {
    path: '/config',
    name: 'config',
    redirect: '/config/user',
    meta: {
      title: '系统管理',
      enTitle: 'System Configuration',
      code: '4',
    },
    children: [
      {
        path: 'user',
        name: 'user',
        meta: {
          title: '用户管理',
          enTitle: 'User Management',
          code: '4-1',
          banner: '可管理用户，支持关联角色。',
          enBanner: 'You can manage users and support associating roles.',
        },
        component: () => import('@/views/config/user/user.vue'),
      },
      {
        path: 'role',
        name: 'role',
        meta: {
          title: '角色管理',
          enTitle: 'Roles Management',
          code: '4-4',
          banner: '可管理角色，支持分配菜单权限',
          enBanner:
            'Manageable roles with support for assigning menu permissions',
        },
        component: () => import('@/views/config/role/index.vue'),
      },
      {
        path: 'log',
        name: 'log',
        meta: {
          title: '诊断日志',
          enTitle: 'Diagnostic Log',
          code: '4-2',
          banner:
            '可查询诊断日志，可根据租户、设备名称、日志ID、日志类型、卫星ID、采集时间和上报时间查询，支持数据导出。',
          enBanner:
            'You can query diagnostic logs, search by tenant, device name, log ID, log type, satellite ID, collection time, and report time, supports data export.',
        },
        component: () => import('@/views/config/log/index.vue'),
      },
      {
        path: 'org',
        name: 'org',
        meta: {
          title: '组织管理',
          enTitle: 'Organization Management',
          code: '4-3',
          banner: '可管理组织架构，支持多级组织。',
          enBanner:
            'Manage organizational structure, support multi-level organization.',
        },
        component: () => import('@/views/config/org/index.vue'),
      },
    ],
  },
  {
    path: '/site',
    name: 'site',
    meta: {
      title: '站点管理',
      enTitle: 'Site Management',
      code: '1',
    },
    redirect: '/site/site-list',
    children: [
      {
        path: 'site-list',
        name: 'site-list',
        meta: {
          title: '站点列表',
          enTitle: 'Site List',
          code: '1-1',
        },
        component: () => import('@/views/site/index.vue'),
        redirect: '/site/site-list',
        children: [
          {
            path: '',
            name: 'site-index',
            meta: {
              title: '站点列表',
              enTitle: 'Site List',

              code: '1-1',
              banner:
                '可添加站点并设置所属组织和地址。站点提供了设备分组功能。',
              enBanner:
                'You can add a site and set its organization and address. The site provides device grouping functionality.',
            },
            component: () => import('@/views/site/list/index.vue'),
          },
          {
            path: 'site-detail',
            name: 'site-detail',
            meta: {
              title: '站点详情',
              enTitle: 'Site Data',
              code: '1-1',
            },
            component: () => import('@/views/site/detail/index.vue'),
          },
        ],
      },
      {
        path: 'site-location',
        name: 'site-location',
        meta: {
          title: '站点分布',
          enTitle: 'Site Location',

          code: '1-1',
        },
        component: () => import('@/views/site/location/index.vue'),
      },
    ],
  },
]

// 给meta赋值
routes.forEach((element: any) => {
  element.meta = {
    ...element.meta,
    layout: !(element?.meta?.layout === false),
  }
})

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const { meta, name } = to
  const { code, white } = meta
  if (white && name !== 'home') {
    next()
    return
  }
  const authStore = useAuthStore()

  await authStore.queryAuth()
  if (!authStore.resources.includes(code)) {
    if (!white) {
      return next({ path: '/auth' })
    }
  }
  next()
})

export default router
