export default {
  username: 'Account',
  nickname: 'Name',
  btnCreate: 'Create User',
  btnEdit: 'Edit',
  btnDel: 'Delete',
  noData: 'No data yet',
  delTitle: 'Are you sure to delete this user',
  delTip:
    'After the user is deleted, all relevant information records of the user are deleted and cannot be retrieved',
  btnDelete: 'Confirm delete',
  delSuccess: 'Delete successful',
  colUserName: 'Account',
  colNickname: 'Name',
  colRoleName: 'Role',
  colCreateTime: 'Create Time',
  editTitle: 'Edit User',
  newTitle: 'New User',
  labelUserName: 'Account Name',
  userNamePla:
    'The user name is the system login account, which cannot be modified after setting',
  labelPassword: 'Password',
  passwordPla: 'Please enter a password',
  labelComfirmPassword: 'Confirm Password',
  comfirmPasswordPla: 'Please enter the password again',
  labelNickName: 'Name',
  nicknamePla: 'Please enter your name',
  labelMobile: 'Mobile phone number',
  mobilePla: 'Please enter your mobile phone number',
  labelEmail: 'Email',
  emailPla: 'Please enter your email',
  labelOrg: 'Organization',
  orgPla: 'Please select the organization you belong to',
  labelRole: 'Associated role',
  rolePla: 'Please select an associated role',
  saveConcat: 'save as alarm contact',
  comfirmMsg: 'The passwords entered twice are inconsistent',
  msgEnter: 'Please enter',
  msgAccound: 'Please enter 4-20 digits of English or numbers',
  msgSelect: 'Please select',
  msgPwd: 'combination of 8-16 letters, numbers, and special symbols',
  msgEmail: 'Please enter the correct email',
  msgMobile: 'Please enter a correct mobile number',
  newSuccess: 'User created successfully',
  editSuccess: 'User modified successfully',
  operation: 'Operate',
  btnCancel: 'Cancel',
  btnYes: 'OK',
  rnew: 'New',
  rname: 'Name',
  rtime: 'Creation Time',
  rstatus: 'State',
  roperation: 'Operate',
  rclose: 'Close',
  ropen: 'Open',
  redit: 'Edit',
  rdelete: 'Delete',
  rdelTip: 'Are you sure you want to delete this role?',
  rnewTitle: 'New Role',
  reditTitle: 'Edit Role',
  rfname: 'Name',
  rfnamePla: 'Enter',
  rfmenu: 'Menu',
  rfdesc: 'remarks',
  rfdescPla: 'Enter',
  rfenter: 'Enter',
  rfselect: 'Select',
}
