import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { queryUsersCurrentFull } from '@/api'
import { CODE_ENUM } from '@/common'

export const useAuthStore = defineStore('auth', () => {
  const resources: any = ref([])
  const isSuperAdmin = ref(false)
  const sysUserData: any = ref({})
  const queryAuth = async () => {
    if (!resources.value.length) {
      const res: any = await queryUsersCurrentFull()
      if (res?.code === CODE_ENUM.SUCCESS) {
        resources.value = (res.data?.resourceDataList || []).map(
          (item: any) => {
            return item.resourceCode
          }
        )
        isSuperAdmin.value = res.data?.sysUserData?.isSuperAdmin
        sysUserData.value = res.data?.sysUserData || {}
      }
    }
  }

  const clearAuth = () => {
    resources.value = []
  }

  return { resources, queryAuth, clearAuth, isSuperAdmin, sysUserData }
})
