export default {
  title: 'API List',
  tip: 'IoT platform API means that in the Internet of Things platform, developers or device managers can easily call various functions and services of the platform through the API interface, including device management, real-time data monitoring, message push, etc., so as to realize Richer application scenarios and services. For the detailed process of docking, please',
  seeDoc: 'view detailed API documentation',
  colPage: 'Query device list',
  colDetail: 'Query device details',
  colLog: 'Query device log',
  colLocation: 'Query device location',
  colHisLocation: 'Query device historical track',
  index: 'Index',
  apiName: 'API Name',
  url: 'URL',
  desc: 'Description',
  noData: 'No data yet',
}
