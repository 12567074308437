import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 获取文件列表
export const queryFilePage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/file/metadata/info/page',
    params,
  })
}

// 预览文件
export const queryFileDetail = async (params: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/file/metadata/previewDownload',
    params,
  })
}

// 删除文件
export const deleteFile = async (params: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/file/metadata/info/delete',
    params,
  })
}

//添加视频
export const addVideoInfo = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/video/cameras/info/add',
    params,
  })
}

//编辑视频
export const updateVideoInfo = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/video/cameras/info/update',
    params,
  })
}

//删除视频
export const deleteVideoInfo = async (params: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/video/cameras/info/delete',
    params,
  })
}

//获取列表
export const queryVideoPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/video/cameras/info/page',
    params,
  })
}

// 获取平台类型
export const queryVideoType = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/video/cameras/type/query',
    params,
  })
}

// 获取单条视频
export const queryVideoInfo = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/video/cameras/info/get',
    params,
  })
}

//报表查询
export const queryReportPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/report/record/page/query',
    params,
  })
}
//报表数据查看
export const queryReportDataPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/report/record/table/query',
    params,
  })
}

//报表导出
export const exportReportData = async (params: any) => {
  return post({
    suffix: SUFFIX.TIANSHU,
    url: '/report/record/table/export',
    params,
    userConfig: {
      responseType: 'arraybuffer',
    },
  })
}

//报表历史趋势图
export const queryReportChart = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/report/record/history/query',
    params,
  })
}

//报表-删除
export const deleteReport = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/report/record/delete',
    params,
  })
}

// 报表配置分野
export const queryReportConfig = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/report/config/page/query',
    params,
  })
}

// 添加报表配置
export const addReportConfig = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/report/config/add',
    params,
  })
}

// 更新报表配置
export const updateReportConfig = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/report/config/update',
    params,
  })
}

// 配置启用
export const startReportConfig = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/report/config/start',
    params,
  })
}

// 配置暂停
export const stopReportConfig = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/report/config/stop',
    params,
  })
}

// 删除报表配置
export const deleteReportConfig = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/report/config/delete',
    params,
  })
}

// 获取报表配置详情
export const queryReportConfigDetail = async (params: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/report/config/get',
    params,
  })
}

// 子设备变量查询
export const queryReportConfigAttr = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/report/config/code/attr',
    params,
  })
}

// 添加看板
export const addBoardInfo = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/data/bulletin/board/info/add',
    params,
  })
}

// 编辑看板
export const updateBoardInfo = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/data/bulletin/board/info/update',
    params,
  })
}

// 获取视频列表
export const queryBoardVideoList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/data/bulletin/board/video/list',
    params,
  })
}

// 获取统计报表下拉选项
export const queryBoardReportList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/data/bulletin/board/queryReport',
    params,
  })
}

// 获取设备属性元数据
export const queryBoardDeviceMetadataList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/data/bulletin/board/device/metadata/list',
    params,
  })
}

// 看板列表
export const queryBoardList = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/data/bulletin/board/info/list',
    params,
  })
}

// 获取看板-定位详情
export const queryBoardLocationDetail = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/data/bulletin/board/location',
    params,
  })
}

// 获取看板-文本及仪表详情
export const queryBoardCommonDetail = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/data/bulletin/board/data/textOrInstrument',
    params,
  })
}

// 获取看板-视频详情
export const queryBoardVideoDetail = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/data/bulletin/board/data/video',
    params,
  })
}

// 获取看板-报表详情
export const queryBoardReportDetail = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/data/bulletin/board/queryReportData',
    params,
  })
}

// 删除看板
export const deleteBoardInfo = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/data/bulletin/board/info/delete',
    params,
  })
}
