export default {
  username: '帐号',
  nickname: '姓名',
  btnCreate: '创建用户',
  btnEdit: '编辑',
  btnDel: '删除',
  noData: '暂无数据',
  delTitle: '是否确定删除该用户',
  delTip: '用户删除后，该用户的相关信息记录全部删除，无法找回',
  btnDelete: '确认删除',
  delSuccess: '删除成功',
  colUserName: '帐号',
  colNickname: '姓名',
  colRoleName: '角色',
  colCreateTime: '创建时间',
  editTitle: '编辑用户',
  newTitle: '新建用户',
  labelUserName: '帐号名称',
  userNamePla: '用户名为系统登录账号，设置后无法修改',
  labelPassword: '密码',
  passwordPla: '请输入密码',
  labelComfirmPassword: '确认密码',
  comfirmPasswordPla: '请再次输入密码',
  labelNickName: '姓名',
  nicknamePla: '请输入姓名',
  labelMobile: '手机号码',
  mobilePla: '请输入手机号码',
  labelEmail: '邮箱',
  emailPla: '请输入邮箱',
  labelOrg: '所属组织',
  orgPla: '请选择所属组织',
  labelRole: '关联角色',
  rolePla: '请选择关联角色',
  saveConcat: '保存为告警联系人',
  comfirmMsg: '两次输入的密码不一致',
  msgEnter: '请输入',
  msgAccound: '请输入4-20位英文或数字',
  msgSelect: '请选择',
  msgPwd: '字母，数字，特殊符号，两种及以上组合，8-16位字符串',
  msgEmail: '请输入正确的邮箱',
  msgMobile: '请输入正确的手机号码',
  newSuccess: '新建用户成功',
  editSuccess: '修改用户成功',
  operation: '操作',
  btnCancel: '取消',
  btnYes: '确定',
  rnew: '新建角色',
  rname: '角色名称',
  rtime: '创建时间',
  rstatus: '状态',
  roperation: '操作',
  rclose: '关闭',
  ropen: '打开',
  redit: '编辑',
  rdelete: '删除',
  rdelTip: '您确定要删除这个角色吗',
  rnewTitle: '新建角色',
  reditTitle: '编辑角色',
  rfname: '角色',
  rfnamePla: '请输入角色名称',
  rfmenu: '菜单分配',
  rfdesc: '备注',
  rfdescPla: '请输入你的备注',
  rfenter: '请输入',
  rfselect: '请选择',
}
