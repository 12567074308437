import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 添加站点
export const siteInfoAdd = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/site/manage/add',
    params,
  })
}

// 修改站点
export const siteInfoUpdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/site/manage/update',
    params,
  })
}

// 分页
export const querySitePage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/site/manage/page',
    params,
  })
}

// 站点详情
export const querySiteInfo = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/site/manage/detail',
    params,
  })
}

// 站点下拉
export const querySiteTreelist = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/site/manage/treelist',
    params,
  })
}

// 站点分布
export const querySiteDistribution = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/site/manage/distribution',
    params,
  })
}

// 删除站点
export const deleteSiteInfo = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/site/manage/delete',
    params,
  })
}
