export default {
  deviceName: '设备名称',
  deviceNamePla: '设备名称',
  logId: '日志ID',
  deviceSn: '设备SN',
  logType: '日志类型',
  newText: '1分钟内更新的数据',
  autoFetchText: '自动刷新',
  autoFetchTip: '每五秒自动刷新一次',
  transType: '消息来源',
  collectTime: '采集时间',
  createTime: '上报时间',
  noData: '暂无数据',
  logContent: '日志内容',
  lat: '纬度',
  lng: '经度',
  satelliteId: '卫星ID',
  startTime: '开始时间',
  endTime: '结束时间',
  onboardTime: '星上时间',
  exportExcel: '导出',
  parseStatus: '解析状态',
  logTraceability: '日志溯源',
}
