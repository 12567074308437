export default {
  btnNew: 'Create Contact',
  colName: 'Name',
  colPhone: 'Phone Numbe',
  colMail: 'Mailbox',
  colOp: 'Operate',
  btnEdit: 'Edit',
  btnDel: 'Delete',
  labelName: 'Name',
  labelPhone: 'Phone Number',
  labelMail: 'Email',
  delTip:
    'Contact {name} will not be able to receive any alarm push messages, please confirm whether to delete it! ',
  phoneMsg: 'Mobile phone number verification failed',
  emailMsg: 'Email verification failed',
  titleNew: 'Create Contact',
  titleEdit: 'Edit Contact',
  createSuccess: 'Added successfully',
  createError: 'Added failed',
  editSuccess: 'Modified successfully',
  editError: 'Modified failed',
  delSuccess: 'Delete successful',
  delError: 'Delete failed',
  btnCancel: 'Cancel',
  btnYes: 'OK',
  delTitle: 'Delete Tip',
  nameMsg: 'Please fill in your name',
  index: 'Index',
  enter: 'Please enter',
  select: 'Please select',
}
